import './App.css'
import { Home } from './pages/Home'
import { Routes, Route, Navigate } from 'react-router-dom'
import Layout from './components/layout/Layout'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import useTrans from './hooks/useTrans'
import Menu from './pages/Menu'

function App() {
  const { i18n } = useTranslation()
  useTrans()

  useEffect(() => {
    if (i18n.language === 'ar') {
      document.body.dir = 'rtl'
    } else {
      document.body.dir = 'ltr'
    }
    const script = document.createElement('script')
    script.src = 'https://www.foodbooking.com/embedder/js/ewm2.js'
    script.id = 'gloria'
    document.body.appendChild(script)
  }, [i18n.language])
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Navigate to="/home" />} />
        <Route
          path="/home"
          element={
            <Layout>
              <Home />
            </Layout>
          }
        />
        <Route
          path="/menu"
          element={
            <Layout>
              <Menu />
            </Layout>
          }
        />
      </Routes>
    </div>
  )
}

export default App
