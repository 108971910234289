import React, { Fragment, useEffect } from 'react'
import { Menu, Popover, Transition } from '@headlessui/react'
import { GlobeAltIcon, MenuIcon, XIcon } from '@heroicons/react/outline'
import { useTranslation } from 'react-i18next'
import { Link, NavLink } from 'react-router-dom'
import BlackArLogo from '../../assets/arPastryBlack.png'
import engPastryBlack from '../../assets/engPastryBlack.png'
import { GBflag, SAflag } from './Flags'

// const user = {
//   name: 'Whitney Francis',
//   email: 'whitney@example.com',
//   imageUrl:
//     'https://images.unsplash.com/photo-1517365830460-955ce3ccd263?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80',
// }

// const userNavigation = [
//   { name: 'Your Profile', href: '#' },
//   { name: 'Settings', href: '#' },
//   { name: 'Sign out', href: '#' },
// ]

const langs = [
  { name: 'العربية', code: 'ar', country_code: 'sa', dir: 'rtl' },
  { name: 'English', code: 'en', country_code: 'gb' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const Header = () => {
  const { t, i18n } = useTranslation()

  const [dir, setDir] = React.useState('ltr')

  const navigation = [
    { name: t('order'), href: '/home' },
    { name: t('menu'), href: '/menu' },
    // { name: t('locations'), href: '/locations' },
    // { name: t('contact'), href: '/contact' },
  ]
  useEffect(() => {
    if (
      localStorage.i18nextLng === 'ar-SA' ||
      localStorage.i18nextLng === 'ar' ||
      (!('i18nextLng' in localStorage) && i18n.language === 'ar')
    ) {
      document.body.dir = 'rtl'
      setDir('rtl')
    } else {
      document.body.dir = 'ltr'
      setDir('ltr')
    }
  }, [i18n.language])
  return (
    <header className="bg-white shadow">
      <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
        <Popover className="flex justify-between h-16">
          <div className="flex px-2 lg:px-0">
            <div className="flex-shrink-0 flex items-center ">
              <Link to="/home">
                <img
                  className="h-10 w-auto"
                  src={dir === 'rtl' ? BlackArLogo : engPastryBlack}
                  alt="Workflow"
                />
              </Link>
            </div>
            <nav
              aria-label="Global"
              className="hidden lg:ltr:ml-6 lg:rtl:mr-6 lg:flex lg:items-center "
            >
              {navigation.map((item) => (
                <NavLink to={item.href} key={item.name}>
                  {({ isActive }) => (
                    // 'bg-gray-900 text-white px-3 text-center py-2 rounded-md text-sm font-medium'
                    <span
                      className={
                        isActive
                          ? ' bg-gray-900 text-white px-3 mx-2 text-center py-2 rounded-md text-sm font-medium'
                          : ' px-3 py-2 rounded-md text-center  text-sm font-medium text-gray-800 hover:bg-gray-700 hover:text-white'
                      }
                    >
                      {item.name}
                    </span>
                  )}
                </NavLink>
              ))}
            </nav>
          </div>
          <div className="flex-1 flex items-center justify-end px-2 lg:ltr:ml-6 lg:rtl:mr-6 lg:justify-end">
            {/* We can put somthing in here */}
            <Menu as="div" className="ltr:ml-4 rtl:mr-4 relative flex-shrink-0">
              <div>
                <Menu.Button className="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                  <GlobeAltIcon className="h-6 w-6" aria-hidden="true" />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-right absolute ltr:right-0 rtl:left-0 mt-2 w-32 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                  {langs.map((item) => (
                    <Menu.Item key={item.name}>
                      {({ active }) => (
                        <button
                          style={{
                            fontWeight:
                              i18n.resolvedLanguage === item.code
                                ? 'bold'
                                : 'normal',
                          }}
                          type="button"
                          onClick={() => {
                            i18n.changeLanguage(item.code)
                          }}
                          className={classNames(
                            i18n.resolvedLanguage === item.code ? 'hidden' : '',
                            'flex px-4 py-2 text-sm text-gray-700 w-full mx-auto'
                          )}
                        >
                          {item.code === 'ar' && <SAflag />}
                          {item.code === 'en' && <GBflag />}

                          {item.name}
                        </button>
                      )}
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Transition>
            </Menu>
          </div>

          <div className="flex items-center lg:hidden">
            {/* Mobile menu button */}
            <Popover.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
              <span className="sr-only">Open main menu</span>
              <MenuIcon className="block h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Transition.Root as={Fragment}>
            <div className="lg:hidden">
              <Transition.Child
                as={Fragment}
                enter="duration-150 ease-out"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="duration-150 ease-in"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Popover.Overlay
                  className="z-20 fixed inset-0 bg-black bg-opacity-25"
                  aria-hidden="true"
                />
              </Transition.Child>

              <Transition.Child
                as={Fragment}
                enter="duration-150 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="duration-150 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Popover.Panel
                  focus
                  className="z-30 absolute top-0 right-0 max-w-none w-full p-2 transition transform origin-top"
                >
                  <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y divide-gray-200">
                    <div className="pt-3 pb-2">
                      <div className="flex items-center justify-between px-4">
                        <div>
                          <img
                            className="h-8 w-auto"
                            src={BlackArLogo}
                            alt="Workflow"
                          />
                        </div>
                        <div className="-mr-2">
                          <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                            <span className="sr-only">Close menu</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </Popover.Button>
                        </div>
                      </div>
                      <div className="mt-3 px-2 space-y-1">
                        {navigation.map((item) => (
                          <NavLink to={item.href} key={item.name}>
                            {({ isActive }) => (
                              <span
                                className={
                                  isActive
                                    ? 'block rounded-md px-3 py-2 text-base text-gray-900 font-medium bg-gray-200 '
                                    : 'block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800'
                                }
                              >
                                {item.name}
                              </span>
                            )}
                          </NavLink>
                        ))}
                      </div>
                    </div>

                    {/* <div className="pt-4 pb-2">
                      <div className="flex items-center px-5">
                        <div className="flex-shrink-0">
                          <img
                            className="h-10 w-10 rounded-full"
                            src={user.imageUrl}
                            alt=""
                          />
                        </div>
                        <div className="ml-3">
                          <div className="text-base font-medium text-gray-800">
                            {user.name}
                          </div>
                          <div className="text-sm font-medium text-gray-500">
                            {user.email}
                          </div>
                        </div>
                      </div>

                      <div className="mt-3 px-2 space-y-1">
                        {userNavigation.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800"
                          >
                            {item.name}
                          </a>
                        ))}
                      </div>
                    </div> */}
                  </div>
                </Popover.Panel>
              </Transition.Child>
            </div>
          </Transition.Root>
          {/* <div className="hidden lg:ltr:ml-4 lg:rtl:mr-4 lg:flex lg:items-center">
            Profile dropdown
            <Menu as="div" className="ltr:ml-4 rtl:mr-4 relative flex-shrink-0">
              <div>
                <Menu.Button className="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                  <span className="sr-only">Open user menu</span>
                  <img
                    className="h-8 w-8 rounded-full"
                    src={user.imageUrl}
                    alt=""
                  />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-right absolute ltr:right-0 rtl:left-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {userNavigation.map((item) => (
                    <Menu.Item key={item.name}>
                      {({ active }) => (
                        <a
                          href={item.href}
                          className={classNames(
                            active ? 'bg-gray-100' : '',
                            'block px-4 py-2 text-sm text-gray-700'
                          )}
                        >
                          {item.name}
                        </a>
                      )}
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Transition>
            </Menu>
          </div> */}
        </Popover>
      </div>
    </header>
  )
}
