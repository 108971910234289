import { Carousel } from 'react-responsive-carousel'
// import Cover from '../assets/3.png'
import burger from '../assets/ProductsPhotos/burger.jpeg'
import checkenPie from '../assets/ProductsPhotos/checkenPie.jpeg'
import checkenPizza from '../assets/ProductsPhotos/checkenPizza.jpeg'
import falafelLabnah from '../assets/ProductsPhotos/falafelLabnah.jpeg'
import graps from '../assets/ProductsPhotos/graps.jpeg'
import hotdogPizza from '../assets/ProductsPhotos/hotdogPizza.jpeg'
import kababMeat from '../assets/ProductsPhotos/kababMeat.jpeg'
import kokhCheese from '../assets/ProductsPhotos/kokhCheese.jpeg'
import meatCheeseEgge from '../assets/ProductsPhotos/meatCheeseEgge.jpeg'
import mexican from '../assets/ProductsPhotos/mexican.jpeg'
import pizzaPie from '../assets/ProductsPhotos/pizzaPie.jpeg'
import sheshTaouk from '../assets/ProductsPhotos/sheshTawouk.jpeg'
import tajeenChecken from '../assets/ProductsPhotos/tajeenChecken.jpeg'
import zaater from '../assets/ProductsPhotos/zaater.jpeg'
import zaaterKokh from '../assets/ProductsPhotos/zaaterKokh.jpeg'

import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader

const ProductsPhotos = () => {
  return (
    <div className="max-w-xl m-3 rounded-lg">
      {/* <img className="object-cover  rounded-lg " src={Cover} alt="" /> */}
      <Carousel
        infiniteLoop={true}
        autoPlay={true}
        axis="vertical"
        interval={3000}
        showThumbs={false}
        showStatus={false}
        // dynamicHeight={false}
      >
        <div>
          <img src={burger} alt="" />
          <p className="legend">برجر لحم </p>
        </div>
        <div>
          <img src={checkenPie} alt="" />
          <p className="legend">دجاج بالعجين</p>
        </div>
        <div>
          <img src={checkenPizza} alt="" />
          <p className="legend">بيتزا دجاج </p>
        </div>
        <div>
          <img src={falafelLabnah} alt="" />
          <p className="legend">فلافل باللبنة </p>
        </div>
        <div>
          <img src={graps} alt="" />
          <p className="legend">ورق عنب </p>
        </div>
        <div>
          <img src={hotdogPizza} alt="" />
          <p className="legend">بيتزاهوت دوج</p>
        </div>
        <div>
          <img src={kababMeat} alt="" />
          <p className="legend">كباب لحم </p>
        </div>
        <div>
          <img src={kokhCheese} alt="" />
          <p className="legend">جبن الكوخ </p>
        </div>
        <div>
          <img src={meatCheeseEgge} alt="" />
          <p className="legend">لحم بالجبن والبيض</p>
        </div>
        <div>
          <img src={mexican} alt="" />
          <p className="legend">مكسيكي دجاج</p>
        </div>
        <div>
          <img src={pizzaPie} alt="" />
          <p className="legend">فطيرة بيتزا</p>
        </div>
        <div>
          <img src={sheshTaouk} alt="" />
          <p className="legend">شيش طاووق</p>
        </div>
        <div>
          <img src={tajeenChecken} alt="" />
          <p className="legend">طاجن دجاج</p>
        </div>
        <div>
          <img src={zaater} alt="" />
          <p className="legend">زعتر</p>
        </div>
        <div>
          <img src={zaaterKokh} alt="" />
          <p className="legend">زعتر الكوخ</p>
        </div>
      </Carousel>
    </div>
  )
}

export default ProductsPhotos
