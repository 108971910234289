import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const useTrans = () => {
  const { i18n } = useTranslation()
  useEffect(() => {
    if (
      localStorage.i18nextLng === 'ar-SA' ||
      localStorage.i18nextLng === 'ar' ||
      (!('i18nextLng' in localStorage) && i18n.language === 'ar')
    ) {
      document.body.dir = 'rtl'
    } else {
      document.body.dir = 'ltr'
    }
  }, [i18n.language])
}

export default useTrans
