import React from 'react'
import Footer from './Footer'
import { Header } from './Header'

const Layout = (props) => {
  return (
    <div className="min-h-screen flex flex-col ">
      <Header />
      <div className="flex-1 flex flex-col">{props.children}</div>
      <Footer />
    </div>
  )
}

export default Layout
