import { useTranslation } from 'react-i18next'
import ProductsPhotos from '../components/ProductsPhotos'

export const Home = () => {
  const { t } = useTranslation()
  const branches = [
    {
      name: t('pastry_jubail_city'),
      data_glf_cuid: '77b56173-7ad3-4c7b-a3cd-f5e1d82a277c',
      data_glf_ruid: '15482a1e-f1bc-4e84-be43-9431a6d1f111',
    },
    {
      name: t('pastry_nada'),
      data_glf_cuid: '77b56173-7ad3-4c7b-a3cd-f5e1d82a277c',
      data_glf_ruid: '707b5c52-3467-4a53-a6fa-3f788b029f14',
    },
    {
      name: t('pastry_fanateer'),
      data_glf_cuid: '77b56173-7ad3-4c7b-a3cd-f5e1d82a277c',
      data_glf_ruid: '6de728bf-ec26-4b24-913a-eb8d1beba50e',
    },
    {
      name: t('pastry_fiha'),
      data_glf_cuid: '77b56173-7ad3-4c7b-a3cd-f5e1d82a277c',
      data_glf_ruid: '417b8ec0-f622-458f-b6fd-14db088f42ec',
    },
  ]

  return (
    <div className="flex flex-col items-center">
      <ProductsPhotos />
      {branches.map((branch) => (
        <div
          key={branch.name}
          className="p-3 m-2 bg-gray-50 flex justify-between w-full max-w-2xl rounded-md"
        >
          <div className="flex flex-col items-left font-semibold">
            <div className="text-xl tracking-tight font-extrabold text-gray-900 sm:text-1xl md:text-2xl">
              {' '}
              {branch.name}
            </div>
            {/* <div className="flex mt-1 self-start">
              <button className="px-2 text-blue-800 text-xs font-medium  cursor-pointer rtl:border-l ltr:border-r">
                {' '}
                {t('location')}
              </button>
              <div className="px-2 text-blue-800 text-xs font-medium  cursor-pointer">
                {' '}
                {t('opening_hours')}
              </div>
            </div> */}
          </div>
          <div className="self-center  p-2 bg-yellow-300 rounded-md cursor-pointer hover:shadow-md ">
            <button
              // onClick={() => setCounter('')}
              // className="glf-button"
              data-glf-cuid={branch.data_glf_cuid}
              data-glf-ruid={branch.data_glf_ruid}
            >
              {t('order_now')}
            </button>
          </div>
        </div>
      ))}
    </div>
  )
}
