import React from 'react'
import menu1 from '../assets/menu/menu 1.jpeg'
import menu2 from '../assets/menu/menu 2.jpeg'

const Menu = () => {
  return (
    <div className="flex flex-col max-w-6xl mx-auto py-3 px-2 space-y-2">
      <img className="object-cover  rounded-lg " src={menu2} alt="" />
      <img className="object-cover  rounded-lg " src={menu1} alt="" />
    </div>
  )
}

export default Menu
