import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import 'flag-icons/css/flag-icons.min.css'
import App from './App'
import '../src/components/I18n'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { Amplify } from 'aws-amplify'
import awsExports from './aws-exports'
import { Suspense } from 'react'
// import SyncLoader from 'react-spinners/SyncLoader'

Amplify.configure(awsExports)

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  color: 'green',
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Suspense
        fallback={
          // <SyncLoader css={style} size={20} color={'green'} margin={4} />
          <div style={style}> Loading </div>
        }
      >
        <App />
      </Suspense>
    </BrowserRouter>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
